<template>
  <div class="signUp">
    <div class="content">
      <div class="left">
        <user-navbar-component/>
      </div>
      <div class="right">
        <div class="contentWrap">
          <input type="file" id="assetsFieldHandle" @change="onChange" ref="file" accept=".pdf,.jpg,.jpeg,.png,.docx,.txt" style="display: none">
          <label for="assetsFieldHandle" class="contentItem"  v-if="!file">
            <div class="imageWrap">
              <img :src="require('/src/assets/svg/add.svg')" alt="">
            </div>
            Upload file
          </label>
          <div class="contentItem" v-for="file in files" :key="file.id" @click="downloadFile(file.fileUrl, file.title, file.fileExtension)">
            <div class="deleteDocument" @click="(e) => deleteDocument(e, file)">
              <img :src="require('/src/assets/svg/deleteFile.svg')" alt="">
            </div>
            <div class="imageWrap">
              <img :src="require('/src/assets/svg/pdf.svg')" alt="" v-if="file.fileExtension === '.png'">
              <img :src="require('/src/assets/svg/pdf.svg')" alt="" v-if="file.fileExtension === '.pdf'">
              <img :src="require('/src/assets/svg/txt.svg')" alt="" v-if="file.fileExtension === '.txt'">
              <img :src="require('/src/assets/svg/mDoc.svg')" alt="" v-if="file.fileExtension === '.docx' || file.fileExtension === '.doc' ||  file.fileExtension === '.docm'">
              <img :src="require('/src/assets/svg/exel.svg')" alt="" v-if="file.fileExtension === '.xlsx' || file.fileExtension === '.xlsm' || file.fileExtension === '.xlsb' || file.fileExtension === '.xltx'">
            </div>
            {{ file.title + file.fileExtension }}
            <br>
          </div>
          <div class="contentItem" v-if="file" @click="sendFile">
            <div class="imageWrap">
              <img :src="require('/src/assets/svg/add.svg')" alt="">
            </div>
            Upload {{file.name}}?
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {mapActions, mapGetters, mapMutations} from "vuex";
import userNavbarComponent from "@/components/userNavbarComponent.vue";
export default {
  name: 'myDocuments',
  components: {
    userNavbarComponent
  },
  data() {
    return {
      files: [],
      file: null,
    }
  },
  created() {
    this.getDocumentations()
  },
  mounted () {
    // this.register()
  },
  computed: {
    ...mapGetters(['StateToken']),
  },
  methods: {
    deleteDocument(e, file) {
      e.stopPropagation()
      e.preventDefault()
      axios.delete(`https://portal.baltecousa.com/api/documents/${file.id}`, {
        headers: { authorization: `Bearer ${this.StateToken.access}`}
      }).then( () => {
        this.getDocumentations()
      })
    },
    onChange() {
      this.file = this.$refs.file.files[0]
    },
    sendFile(e) {
      e.preventDefault()
      const formData = new FormData();
      formData.append('file', this.file);
      const headers = { 'Content-Type': 'multipart/form-data',  'authorization': `Bearer ${this.StateToken.access}` };
      axios.post('https://portal.baltecousa.com/api/documents', {
        title: this.file.name.split('.')[0],
        description: 'Change description',
        file: this.file,
      }, {headers}).then(resp => {
        this.getDocumentations()
        this.file = null
      })
    },
    downloadFile(url, filename, fileExtension) {
      const link = document.createElement("a");
      // If you don't know the name or want to use
      // the webserver default set name = ''
      link.setAttribute('download', `${filename}${fileExtension}`);
      link.href = url;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      link.remove();

    },

    getDocumentations() {
      axios.get(`https://portal.baltecousa.com/api/documents`, {
        headers: { authorization: `Bearer ${this.StateToken.access}`}
      }).then(resp => {
        this.files = resp.data.data
      })
    },
  }
}
</script>
<style scoped lang="scss">
@import "src/assets/styles";
.signUp {
  min-height: $pageHeight;
  display: flex;
  position: relative;
  .left {
    height: $pageHeight;
    width: 50vw;
    background: $dark72;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 65px;
    background: url("@/assets/img/bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    .links {
      .link {
        margin-top: 32px;
        color: $light-silver;
        font-size: 16px;
        cursor: pointer;
      }
      .active {
        color: $gold-text;
      }
    }
    h1 {
      text-transform: uppercase;
      font-size: 40px;
      font-weight: 300;
      margin: 0;
      padding: 0;
      color: #fff;
    }
  }
  .right {
    height: calc($pageHeight - 130px) ;
    width: calc(50vw - 95px * 2);
    padding: 65px 95px;
    overflow: auto;

    .contentWrap {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      margin-bottom: 16px;
      .contentItem {
        text-align: center;
        position: relative;
        cursor: pointer;
        .deleteDocument {
          cursor: pointer;
          position: absolute;
          padding: 10px;
          right: 0;
          top: 0;

        }
        .imageWrap {
          padding: 70px 50px;
          background: #D9D9D9;
          border-radius: 12px;
          margin-bottom: 12px;
          img {
            height: 100px;
            width: 80px;
          }
        }
      }
    }



    .form {
      width: 356px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .inputLabel {
      width: 365px;
      color: $black-text;
      font-size: 16px;
      font-weight: $font-light;
      letter-spacing: 0;
      margin-bottom: 12px;
    }
    input {
      width: calc(100% - 16px * 2);
      font-size: 16px;
      font-weight: $font-regular;
      margin-bottom: 12px;
      padding: 12px 16px;
      border-radius: 4px;
      outline: none;
      border: 1px solid $silver;
      color: $black-text;
      &::placeholder {
        color: $dark72;
      }
      &:hover {
        border: 1px solid $gold-text;
      }
      &:focus {
        border: 1px solid $gold-text;
        color: $black-text;
      }
    }
    .login {
      width: 100%;
      background: $black-text;
      color: #fff;
      border-radius: 139px;
      padding: 12px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      text-transform: uppercase;
    }
    .registration {
      width: 100%;
      background: #fff;
      color: $black-text;
      border: 1px solid $black-text;
      border-radius: 139px;
      padding: 12px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      text-transform: uppercase;
      margin-top: 16px;
    }
    a {
      color: $dark72;
      text-decoration: none;
      margin-top: 16px;
      font-size: 12px;
    }
  }

  .content {
    display: flex;
    position: fixed;
  }
}

@media screen and (max-width: 768px) {
  .signUp {
    height: auto;

    .content {
      position: relative;
      flex-direction: column;
      width: 100vw;

      .left {
        width: 100vw;
        height: unset;
        padding-top: 0;

        .leftWrap {
          padding: 40px 15px;
          width: calc(100vw - 15px * 2);

          img {
            width: 100%;
          }
        }
      }

      .right {
        padding: 40px 15px;
        height: auto;
        width: calc(100vw - 15px * 2);
        .hint {
          width: auto;
        }
        .form {
          align-items: flex-start;
        }
        a {
          align-self: center;
        }
      }
    }
  }
}
</style>
