<template>
  <div class="signUp">
    <div class="content">
      <div class="left">
        <user-navbar-component/>
      </div>
      <div class="right">
        <div class="hint" v-if="hint">
          <div class="hintLeft">
            <img :src="require('/src/assets/svg/hint.svg')" alt="">
          </div>
          <div class="hintRight">
            <div class="top">
              Success!
            </div>
            <div class="bot">
              Your password has been successfully changed!
            </div>
          </div>
        </div>
        <div class="hint" v-if="hintValidation">
          <div class="hintLeft">
            <img :src="require('/src/assets/svg/hint.svg')" alt="">
          </div>
          <div class="hintRight">
            <div class="top">
              Validation error!
            </div>
            <div class="bot">
              {{hintValidationMessage}}
            </div>
          </div>
        </div>
        <div class="contentWrap">

          <div class="form">
            <div class="inputLabel">
              Your Current Password:
            </div>
            <input type="password" placeholder="********" v-model="currentPassword">
            <div class="inputLabel">
              New Password:
            </div>
            <input type="password" placeholder="********" v-model="newPassword">
            <div class="inputLabel">
              Repeat New Password:
            </div>
            <input type="password" placeholder="********" v-model="newPasswordConfirm">
            <div class="createBtn" @click="updatePassword">
              Update password
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {mapActions, mapGetters, mapMutations} from "vuex";
import userNavbarComponent from "@/components/userNavbarComponent.vue";
export default {
  name: 'signup',
  components: {
    userNavbarComponent
  },
  data() {
    return {
      currentPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
      hint: false,
      hintValidation: false,
      hintValidationMessage: '',
    }
  },
  created() {
  },
  mounted () {
  },
  computed: {
    ...mapGetters(['StateToken'])
  },
  methods: {
    ...mapMutations(['logout']),
    logoutUser() {
      this.logout()
      this.$router.push('/sign-in')
    },
    doUpdate() {
      axios.put('https://portal.baltecousa.com/api/profile/password', {
        currentPassword: this.currentPassword,
        password: this.newPassword,
        passwordConfirmation: this.newPasswordConfirm,
      }, {
        headers: { authorization: `Bearer ${this.StateToken.access}`}
      }).then( () => {
        this.hint = true
        this.currentPassword = ''
        this.newPassword = ''
        this.newPasswordConfirm = ''
      }).catch(error => {
        console.log(error)
        if (error.response.data.ErrorType === 2) {
          this.hintValidation = true
          this.hintValidationMessage = 'The current password is not correct'
        }
      })
    },
    updatePassword() {
      const reg = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}")
      if (!reg.test(this.newPassword)) {
        this.hintValidation = true
        this.hintValidationMessage = 'New password must be minimum eight characters, \n at least one uppercase letter, one lowercase letter and one number'
        return
      }
      if (this.newPassword !== this.newPasswordConfirm) {
        this.hintValidation = true
        this.hintValidationMessage = 'The new password does not match the confirmation'
        return
      }
      this.hintValidationMessage = ''
      this.hintValidation = false
      try {
        this.doUpdate()
      }
      catch (error) {
        console.log(error.response.data)
      }
        // this.hintMessage = error.response.data.Message

    }
  }
}
</script>
<style scoped lang="scss">
@import "src/assets/styles";
.signUp {
  min-height: $pageHeight;
  display: flex;
  position: relative;
  .left {
    height: $pageHeight;
    width: 50vw;
    background: $dark72;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 65px;
    background: url("@/assets/img/bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    .links {
      .link {
        margin-top: 32px;
        color: $light-silver;
        font-size: 16px;
        cursor: pointer;
      }
      .active {
        color: $gold-text;
      }
    }
    h1 {
      text-transform: uppercase;
      font-size: 40px;
      font-weight: 300;
      margin: 0;
      padding: 0;
      color: #fff;
    }
  }
  .right {
    height: calc($pageHeight - 130px) ;
    width: calc(50vw - 95px * 2);
    padding: 65px 95px;
    overflow: auto;

    .hint {
      max-width: 350px;
      display: flex;
      align-items: center;
      background: $light-silver;
      padding: 16px 24px;
      border-radius: 8px;
      width: fit-content;
      margin-bottom: 16px;
      .hintLeft {
        margin-right: 12px;
      }
      .hintRight {
        font-size: 16px;
        .top {
          font-weight: 600;
        }
        .bot {
          font-weight: 400;
        }
      }
    }
    .contentWrap {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      margin-bottom: 16px;
      .contentItem {
        text-align: center;
        .imageWrap {
          padding: 70px 50px;
          background: #D9D9D9;
          border-radius: 12px;
          margin-bottom: 12px;
        }
      }
    }



    .form {
      width: 356px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .inputLabel {
      width: 365px;
      color: $black-text;
      font-size: 16px;
      font-weight: $font-light;
      letter-spacing: 0;
      margin-bottom: 12px;
    }
    input, textarea {
      width: calc(100% - 16px * 2);
      font-size: 16px;
      font-weight: $font-regular;
      margin-bottom: 12px;
      padding: 12px 16px;
      border-radius: 4px;
      outline: none;
      border: 1px solid $silver;
      color: $black-text;
      &::placeholder {
        color: $dark72;
      }
      &:hover {
        border: 1px solid $gold-text;
      }
      &:focus {
        border: 1px solid $gold-text;
        color: $black-text;
      }
    }
    .login {
      width: 100%;
      background: $black-text;
      color: #fff;
      border-radius: 139px;
      padding: 12px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      text-transform: uppercase;
    }
    .registration {
      width: 100%;
      background: #fff;
      color: $black-text;
      border: 1px solid $black-text;
      border-radius: 139px;
      padding: 12px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      text-transform: uppercase;
      margin-top: 16px;
    }
    a {
      color: $dark72;
      text-decoration: none;
      margin-top: 16px;
      font-size: 12px;
    }
    .createBtn {
      width: 100%;
      background: $black-text;
      color: #fff;
      border-radius: 139px;
      padding: 12px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      text-transform: uppercase;
    }
  }

  .content {
    display: flex;
    position: fixed;
  }
}
</style>
