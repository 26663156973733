<template>
  <div class="documentation">
    <navbar-component/>
    <div class="content">
      <div id="map" ref="map"></div>
      <div class="createWrap" v-if="createWindow">
        <div class="createWindow">
          <div class="close">
            <img :src="require('/src/assets/svg/closeBlack.svg')" alt="" @click="createWindow=false">
          </div>
          <div class="createWindowContent">
            <div class="inputLabel">
              Marker name:
            </div>
            <input type="text" placeholder="Name" v-model="newItemName">
            <div class="inputLabel">
              Marker address:
            </div>
            <input type="text" placeholder="Address" v-model="newItemAddress">
            <div class="inputLabel">
              Marker products:
            </div>
            <input type="text" placeholder="Marker products" v-model="newItemProducts">
            <div class="inputLabel">
              Marker zip code:
            </div>
            <input type="text" placeholder="Zip code" v-model="newItemPost">
            <div class="inputLabel">
              Marker lat:
            </div>
            <input type="number" placeholder="Lat" v-model="newItemLat">
            <div class="inputLabel">
              Marker lng:
            </div>
            <input type="number" placeholder="Lng" v-model="newItemLng">
            <div class="inputLabel">
              Marker Description:
            </div>
            <input type="text" placeholder="Description" v-model="newItemDesc">

            <div class="createBtn" @click="createVideo">
              Create
            </div>
          </div>
        </div>
      </div>
      <div class="createWrap" v-if="editWindow">
        <div class="createWindow">
          <div class="close">
            <img :src="require('/src/assets/svg/closeBlack.svg')" alt="" @click="editWindow=false">
          </div>
          <div class="createWindowContent">
            <div class="inputLabel">
              Marker name:
            </div>
            <input type="text" placeholder="Marker name" v-model="editFileObj.placeName">
            <div class="inputLabel">
              Marker address:
            </div>
            <input type="text" placeholder="Marker address" v-model="editFileObj.placeAddress">

            <div class="inputLabel">
              Marker products:
            </div>
            <input type="text" placeholder="Marker products" v-model="editFileObj.productsData">

            <div class="inputLabel">
              Marker description:
            </div>
            <input type="text" placeholder="Marker description" v-model="editFileObj.placeDescription">
            <div class="inputLabel">
              Marker lat:
            </div>
            <input type="text" placeholder="Marker lat" v-model="editFileObj.lat">
            <div class="inputLabel">
              Marker lng:
            </div>
            <input type="text" placeholder="Marker lat" v-model="editFileObj.lng">
            <div class="inputLabel">
              Marker zip code:
            </div>
            <input type="text" placeholder="Marker zip code" v-model="editFileObj.placeId">
            <div class="createBtn" @click="editFile">
              Save
            </div>
          </div>
        </div>
      </div>
      <div class="tableWrap">
        <div class="table">
          <div class="tableTopActions">
            <div class="tableTopActionsLeft">
              <div class="addNew" @click="createWindow = true">
                <img :src="require('/src/assets/svg/addFile.svg')" alt="">
                add new
              </div>
              <!--              <div class="export">-->
              <!--                <img :src="require('/src/assets/svg/exportFile.svg')" alt="">-->
              <!--                export-->
              <!--                <img :src="require('/src/assets/svg/arrowRight.svg')" alt="">-->
              <!--              </div>-->
            </div>
            <div class="search">
              <input type="text" placeholder="Zip code" v-model="filter" @input="debounce">
            </div>
            <div class="results">
              Results

              <div class="customSelect" @click="customSelect = !customSelect">
                {{activeSelectItem}}
                <img :src="require('/src/assets/svg/arrowDownBlack.svg')" alt="">
                <div class="customSelectWindow" v-if="customSelect">
                  <div class="customSelectItem" @click="setSelectItem(15)">15</div>
                  <div class="customSelectItemDivider"></div>
                  <div class="customSelectItem" @click="setSelectItem(25)">25</div>
                  <div class="customSelectItemDivider"></div>
                  <div class="customSelectItem" @click="setSelectItem(50)">50</div>
                  <div class="customSelectItemDivider"></div>
                  <div class="customSelectItem" @click="setSelectItem(100)">100</div>
                </div>
              </div>
            </div>
          </div>
          <div class="tableMidWrap">
            <div class="tableData">
              <table>
                <tr class="tableHeader">
<!--                  <th>-->
<!--                    Check-->
<!--                  </th>-->
                  <th>
                    Action
                  </th>
                  <th>
                    Marker name
                  </th>
                  <th>
                    Marker address
                  </th>
                  <th class="markerDescription">
                    Marker description
                  </th>
                  <th>
                    Products
                  </th>
                  <th>
                    Marker zip code
                  </th>
                </tr>
                <tr v-for="file in files" :key="file.id">
<!--                  <td>-->
<!--                    <input type="checkbox">-->
<!--                  </td>-->
                  <td>
                    <div class="tableActions">
                      <img :src="require('/src/assets/svg/visible.svg')" alt="" class="clickable" v-if="file.isVisible" @click="changeFileVisibility(file)">
                      <img :src="require('/src/assets/svg/invisible.svg')" alt="" class="clickable" v-else @click="changeFileVisibility(file)">
                      <img :src="require('/src/assets/svg/actionsDivider.svg')" alt="">
                      <img :src="require('/src/assets/svg/edit.svg')" alt="" class="clickable" @click="openEditMode(file)">
                      <img :src="require('/src/assets/svg/actionsDivider.svg')" alt="">
                      <img :src="require('/src/assets/svg/delete.svg')" alt="" class="clickable" @click="deleteFile(file)">
                    </div>
                  </td>
                  <td @click="centeredMap({lat:file.lat, lng:file.lng})" style="cursor: pointer">
                    {{ file.placeName }}
                  </td>
                  <td @click="centeredMap({lat:file.lat, lng:file.lng})" style="cursor: pointer">
                    {{file.placeAddress}}
                  </td>
                  <td @click="centeredMap({lat:file.lat, lng:file.lng})" style="cursor: pointer"  class="markerDescription">
                    {{file.placeDescription}}
                  </td>
                  <td>
                    {{file.productsData}}
                  </td>
                  <td>
                    {{file.placeId}}
                  </td>
                </tr>
              </table>
            </div>
            <GmapMap
                class="mapsWrap"
                ref="mapRef"
                :center="center"
                :zoom="zoom"
                map-type-id="terrain"
                style="width: 100%; min-height: 300px; height: 100%; margin-top: 16px;"
            >
<!--              <GmapMarker-->
<!--                  v-for="(m, index) in files"-->
<!--                  :key="index"-->
<!--                  :position="google && new google.maps.LatLng(m.lat, m.lng)"-->
<!--                  :clickable="true"-->
<!--                  @click="clickAtMarker(m)"-->
<!--              />-->
            </GmapMap>
          </div>
          <div class="tableBotActions">
            <div class="delete" style="opacity: 0">
              <img :src="require('/src/assets/svg/deleteBig.svg')" alt="">
              DELETE SELECTED VIDEOS
            </div>
            <div class="tableBotActionsRight">
              <div class="prev" @click="goPrev" v-if="activePage > 1">
                <img :src="require('/src/assets/svg/arrowRight.svg')" alt="">
                PREV
              </div>
              <div class="pagination">
                <div class="pageItem firstPage" v-if="firstPage !== activePage" @click="goToPage(1)">
                  {{firstPage}}
                </div>
                <div class="pageItem"  v-if="activePage > 1 && activePage !== 1">
                  ...
                </div>
                <div class="pageItem activePage">
                  {{activePage}}
                </div>
                <div class="pageItem"  v-if="activePage < lastPage ">
                  ...
                </div>
                <div class="pageItem lastPage" v-if="lastPage !== activePage"  @click="goToPage(lastPage)">
                  {{lastPage}}
                </div>
              </div>

              <div class="next" @click="goNext" v-if="activePage !== pages">
                NEXT
                <img :src="require('/src/assets/svg/arrowRight.svg')" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navbarComponent from "@/components/navbarComponent.vue";
import axios from "axios";
import {mapActions, mapGetters} from "vuex";
import {gmapApi} from 'vue2-google-maps'

export default {
  name: 'documentationView',
  components: {
    navbarComponent
  },
  data() {
    return {
      map: null,
      center: {lat:0, lng:0},
      zoom: 10,
      customSelect: false,
      activeSelectItem: 15,
      files: [],
      file: null,
      activePage: 1,
      firstPage: 1,
      lastPage: null,
      pages: 1,
      filter: '',
      timeout: null,
      createWindow: false,
      newItemName: '',
      newItemDesc: '',
      newItemAddress: '',
      newItemPost: '',
      newItemLat: '',
      newItemLng: '',
      newItemProducts: '',
      markers: [],
      editWindow: false,
      editFileObj: {}
    }
  },
  created() {
    this.getDocumentations()
  },
  mounted () {
    this.$refs.mapRef.$mapPromise.then((map) => {
      this.map = map
      // window.open(this.map);
    })
  },
  computed: {
    ...mapGetters(['StateToken']),
    google: gmapApi
  },
  methods: {
    editFile() {
      axios.put(`https://portal.baltecousa.com/api/locations/${this.editFileObj.id}`, {
        "placeName": this.editFileObj.placeName,
        "placeAddress": this.editFileObj.placeAddress,
        "placeDescription": this.editFileObj.placeDescription,
        "placeId": this.editFileObj.placeId,
        "lat": this.editFileObj.lat,
        "lng": this.editFileObj.lng,
        "productsData": this.editFileObj.productsData,
      }, {
        headers: { authorization: `Bearer ${this.StateToken.access}`}
      }).then( () => {
        this.getDocumentations()
        this.editWindow = false
      })
    },
    openEditMode(file) {
      this.editFileObj = file
      this.editWindow = true
    },
    centeredMap(center) {
      this.center = center
      this.zoom = 15
    },
    addMarker() {
      for (let i = 0; i < this.markers.length; i++) {
        this.markers[i].setMap(null);
      }
      this.files.forEach( item => {
        let marker = new this.google.maps.Marker({
          position: new this.google.maps.LatLng(item.lat, item.lng),
          draggable: false,
          map: this.map
        });
        marker.setMap(this.map)
        this.markers.push(marker)

        marker.addListener('click', () => this.clickAtMarker(marker, item))
      })
    },

    clickAtMarker(marker, item) {
      this.centeredMap({lat:item.lat, lng:item.lng})
      let window = new this.google.maps.InfoWindow({
        // size: new this.google.maps.Size(150, 50),
        content: `<p>${item.placeName}</p><p>${item.placeAddress}</p><p>${item.placeId}</p><a href='${item.placeDescription}'>${item.placeDescription}</a>`
      })
      window.open(this.map, marker);
    },
    createVideo() {
      let video = {
        "placeId": this.newItemPost,
        "lng": this.newItemLng,
        "lat": this.newItemLat,
        "placeName": this.newItemName,
        "placeAddress": this.newItemAddress,
        "placeDescription": this.newItemDesc,
        "productsData": this.newItemProducts,
        "isVisible": true,
      }
      axios.post(`https://portal.baltecousa.com/api/locations/`, video, {
        headers: { authorization: `Bearer ${this.StateToken.access}`}
      }).then(resp => {
        this.createWindow = false

        this.newItemLng = 0
        this.newItemLat = 0
        this.newItemName = ''
        this.newItemAddress = ''
        this.newItemDesc = ''
        this.newItemProducts = ''
        this.getDocumentations()
      })
    },
    goToPage(page) {
      this.activePage = page
      this.getDocumentations()
    },
    getHumanDate (date) {
      return new Date(date).toLocaleString()
    },
    debounce(e) {
      if (this.timeout)
        clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.filter = String(e.target.value)
        this.getDocumentations()
      }, 500); // delay
    },
    goNext() {
      if (this.activePage < this.pages)
        this.activePage++
      this.getDocumentations()
    },
    goPrev() {
      if (this.activePage > 1)
        this.activePage--
      this.getDocumentations()
    },
    changeFileVisibility(file) {
      file.isVisible = !file.isVisible
      axios.put(`https://portal.baltecousa.com/api/locations/${file.id}`, file, {
        headers: { authorization: `Bearer ${this.StateToken.access}`}
      }).then( () => {
        this.getDocumentations()
      })
    },
    deleteFile(file) {
      axios.delete(`https://portal.baltecousa.com/api/locations/${file.id}`, {
        headers: { authorization: `Bearer ${this.StateToken.access}`}
      }).then( () => {
        this.getDocumentations()
      })
    },
    async downloadFile(url, filename, fileExtension) {
      fetch(url, {
        headers: { authorization: `Bearer ${this.StateToken.access}`}
      }).then(res => res.blob()).then(file => {
        let tempUrl = URL.createObjectURL(file, `${filename}${fileExtension}`);
        const aTag = document.createElement("a");
        aTag.href = tempUrl;
        aTag.download = url.replace(/^.*[\\\/]/, '');
        document.body.appendChild(aTag);
        aTag.click();
        URL.revokeObjectURL(tempUrl);
        aTag.remove();
      })

    },
    sendFile() {
      const formData = new FormData();
      formData.append('file', this.file);
      const headers = { 'Content-Type': 'multipart/form-data' };
      axios.post('api/accounts/uploadPhoto', formData, {headers})
    },
    onChange() {
      this.file = this.$refs.file.files[0]
    },
    remove(e) {
      e.preventDefault()
      this.file = null
    },
    dragover(event) {
      event.preventDefault();
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
    },
    getDocumentations() {
      axios.get(`https://portal.baltecousa.com/api/locations/all?Page=${this.activePage}&PageSize=${this.activeSelectItem}&Sorts=-placeId&Filters=placeId@=*${this.filter}`, {
        headers: { authorization: `Bearer ${this.StateToken.access}`}
      }).then(resp => {
        this.files = resp.data.data
        if (this.files.length > 0) {
          this.center= {lat:this.files[0].lat, lng:this.files[0].lng}
        }
        this.pages = resp.data.pages
        this.lastPage = this.pages

        this.addMarker()
      })
    },
    setSelectItem(num) {
      this.activeSelectItem = num
      this.activePage = 1
      this.getDocumentations()
    }
  }
}
</script>
<style scoped lang="scss">
@import "src/assets/styles";
.documentation {
  min-height: $pageHeight;
  display: flex;
  position: relative;
  .content {
    padding-left: 480px;
    padding-right: 288px;
    width: 100%;
    .tableWrap {
      padding: 65px 0 65px 95px;
      .table {
        .tableTopActions {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .tableTopActionsLeft {
            display: flex;
            gap: 12px;
            .addNew {
              height: 20px;
              text-transform: uppercase;
              cursor: pointer;
              padding: 9px 20px;
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 8px;
              background: $black-text;
              border-radius: 139px;
              color: #fff;
              font-size: 12px;
              font-weight: 400;
            }
            .export {
              height: 18px;
              text-transform: uppercase;
              cursor: pointer;
              padding: 9px 20px;
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 8px;
              border: 1px solid $black-text;
              background: #fff;
              border-radius: 139px;
              color: $black-text;
              font-size: 12px;
              font-weight: 400;
            }
          }
          .search {
            input {
              width: calc(256px - 12px * 2);
              border: 1px solid $silver;
              border-radius: 4px;
              outline: none;
              background: url("@/assets/svg/search.svg") $light-silver no-repeat scroll;
              background-position: right;
              background-position-x: calc(100% - 10px);
              padding: 12px 35px 12px 8px;
              font-size: 12px;
              &::placeholder {
                font-size: 12px;
              }
            }
          }
          .results {
            display: flex;
            align-items: center;
            gap: 12px;
            .customSelect {
              position: relative;
              display: flex;
              gap: 10px;
              height: 12px;
              padding: 12px 18px;
              justify-content: center;
              align-items: center;
              background: $light-silver;
              border: 1px solid $silver;
              border-radius: 4px;
              img {
                height: 6px;
                width: 8px;
              }
              .customSelectWindow {
                position: absolute;
                top: 100%;
                width: 100%;
                justify-content: center;
                align-items: center;
                background: $light-silver;
                border: 1px solid $silver;
                .customSelectItem {
                  padding: 10px;
                  text-align: right;
                }
                .customSelectItemDivider {
                  width: 100%;
                  height: 1px;
                  background: $silver;
                }
              }
            }
          }
        }
        .tableMidWrap {
          display: flex;
          flex-direction: column-reverse;
          .tableData {
            width: 100%;
            padding-right: 16px;
            table {
              margin-top: 16px;
              font-family: Roboto, sans-serif;
              border-collapse: collapse;
              border: none;
              width: 100%;
              th {
                color: #fff;
                height: 38px;
                text-align: left;
                padding-left: 16px;
                font-size: 12px;
                font-weight: 500;
              }
              td {
                height: 38px;
                color: $black-text;
                padding-left: 16px;
                text-align: left;
                .tableActions {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  gap: 10px;
                  height: 22px;
                  border-radius: 139px;
                  border: 1px solid $black-text;
                  width: min-content;
                  padding: 0 14px;
                  .clickable {
                    cursor: pointer;
                  }
                }
              }
              tr {
                .fileLink {
                  cursor: pointer;
                }
              }
              .markerDescription {
                width: 100px;
              }
              .fileLink {
                max-width: 150px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }
              .tableHeader {
                background: $dark72;
              }
            }
          }
        }
      }
      .dragAndDrop {
        border: 1px dashed $dark72;
        border-radius: 8px;
        cursor: pointer;
        input {
          display: none;
        }
        label {
          width: 100%;
          height: 100%;
        }
        .dragAndDropBody {
          width: 100%;
          padding: 12px 0;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          cursor: pointer;
          .selectedFile {
            background: $gold-text;
            border-radius: 228px;
            color: #fff;
            display: flex;
            padding: 7px 16px;
            gap: 5px;
          }
          .uploadFileBtn {
            background: $black-text;
            border-radius: 228px;
            color: #fff;
            display: flex;
            padding: 7px 16px;
            gap: 5px;
          }
        }
      }
      .tableBotActions {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .delete {
          background: $red;
          padding: 12px 16px;
          border-radius: 139px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 7px;
          color: #fff;
          font-size: 12px;
        }
        .tableBotActionsRight {
          display: flex;
          gap: 16px;
          .pagination {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            .pageItem {
              cursor: pointer;
              padding: 11px 12px;
              border-radius: 4px;
              font-weight: 600;
              color: $black-text;
            }
            .activePage {
              color: #fff;
              background: $dark72;
            }
          }
          .prev, .next {
            cursor: pointer;
            padding: 12px 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 9px;
            border-radius: 139px;
            border: 1px solid $black-text;
          }
          .prev {
            img {
              transform: rotate(180deg);
            }
          }
          .next {

          }
        }
      }
    }
    .createWrap {
      position: fixed;
      z-index: 20;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background: rgba(0, 0, 0, .4);
      display: flex;
      justify-content: center;
      align-items: center;
      .createWindow {
        border-radius: 12px;
        display: flex;
        width: 360px;
        padding: 26px 32px;
        background: $light-silver;
        flex-direction: column;
        .close {
          display: flex;
          justify-content: flex-end;
          img {
            cursor: pointer;
          }
        }
        .createWindowContent {
          .inputLabel {
            width: 365px;
            color: $black-text;
            font-size: 16px;
            font-weight: $font-light;
            letter-spacing: 0;
            margin-bottom: 12px;
          }
          input, textarea {
            width: calc(100% - 18px * 2);
            font-size: 16px;
            font-weight: $font-regular;
            margin-bottom: 12px;
            padding: 12px 16px;
            border-radius: 4px;
            outline: none;
            border: 1px solid $silver;
            color: $black-text;
            &::placeholder {
              color: $dark72;
            }
            &:hover {
              border: 1px solid $gold-text;
            }
            &:focus {
              border: 1px solid $gold-text;
              color: $black-text;
            }
          }
          .createBtn {
            background: $black-text;
            color: #fff;
            border-radius: 139px;
            padding: 12px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
