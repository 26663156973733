<template>
  <div class="footerWrap">
    <div class="footerComponent">
      <div class="footerLeft">
        <div class="item footerLogo">
          <img :src="require('/src/assets/svg/footerLogo.svg')" alt="footerLogo">
        </div>
        <div class="item">
          <a target="_blank" href="https://baltecousa.com/terms.pdf">Terms & conditions of use </a>
        </div>
        <div class="item">
          <a target="_blank" href="https://baltecousa.com/Warranty.pdf">Warranty</a>
        </div>
        <div class="item">
          <a target="_blank" href="https://baltecousa.com/Installation.pdf">Installation & Care</a>
        </div>

        <div class="item socialWrap">
          <div class="instagram">
            <a target="_blank" href="https://www.instagram.com/baltecousa/">
              <svg viewBox="0 0 16 16">
                <linearGradient id="a" x1="1.464" x2="14.536" y1="14.536" y2="1.464" gradientUnits="userSpaceOnUse">
                  <stop offset="0" stop-color="#FFC107"></stop>
                  <stop offset=".507" stop-color="#F44336"></stop>
                  <stop offset=".99" stop-color="#9C27B0"></stop>
                </linearGradient>
                <path fill="url(#a)" d="M11 0H5a5 5 0 0 0-5 5v6a5 5 0 0
                              0 5 5h6a5 5 0 0 0 5-5V5a5 5 0 0 0-5-5zm3.5 11c0
                              1.93-1.57 3.5-3.5 3.5H5c-1.93
                              0-3.5-1.57-3.5-3.5V5c0-1.93 1.57-3.5
                              3.5-3.5h6c1.93 0 3.5 1.57 3.5 3.5v6z"></path>
                <linearGradient id="b" x1="5.172" x2="10.828" y1="10.828" y2="5.172" gradientUnits="userSpaceOnUse">
                  <stop offset="0" stop-color="#FFC107"></stop>
                  <stop offset=".507" stop-color="#F44336"></stop>
                  <stop offset=".99" stop-color="#9C27B0"></stop>
                </linearGradient>
                <path fill="url(#b)" d="M8 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8zm0 6.5A2.503 2.503 0 0 1 5.5 8c0-1.379 1.122-2.5 2.5-2.5s2.5 1.121 2.5 2.5c0 1.378-1.122 2.5-2.5 2.5z"></path>
                <linearGradient id="c" x1="11.923" x2="12.677" y1="4.077" y2="3.323" gradientUnits="userSpaceOnUse">
                  <stop offset="0" stop-color="#FFC107"></stop>
                  <stop offset=".507" stop-color="#F44336"></stop>
                  <stop offset=".99" stop-color="#9C27B0"></stop>
                </linearGradient>
                <circle cx="12.3" cy="3.7" r=".533" fill="url(#c)"></circle>
              </svg>
            </a>
          </div>
          <div class="instagram">
            <a target="_blank" href="https://www.pinterest.com/baltecousa">
              <img :src="require('/src/assets/svg/pinterest.svg')" alt="footerLogo">
            </a>
          </div>
          <div class="instagram">
            <a target="_blank" href="https://www.houzz.com/">
              <img :src="require('/src/assets/svg/houzz.svg')" alt="footerLogo">
            </a>
          </div>
        </div>



      </div>
      <div class="footerRight">
  <!--      <div class="item">-->
  <!--        <a href="">for designers</a>-->
  <!--      </div>-->
        <div class="item">
          <a href="https://baltecousa.com/brochures.html">Brochures</a>
        </div>
      </div>
    </div>
    <a href="https://u24.gov.ua/" style="color: #323232; height: 60px; text-decoration: none">
      <div class="utited24" style="display: flex; flex-direction: column; justify-content: center; text-align: center;">

        <svg style="max-width: 150px; text-align: center; margin: 0 auto;" width="422" height="40" viewBox="0 0 422 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M124.578 6.14598H145.731V38.7599H124.578V6.14598ZM37.2698 19.9366C37.2698 24.5953 34.7007 26.7877 29.2112 26.7877C23.7218 26.7877 21.1498 24.5982 21.1498 19.9366V6.14598H0V20.7302C0 34.3809 7.63894 39.2252 29.2112 39.2252C50.7835 39.2252 58.4225 34.3724 58.4225 20.7302V6.14598H37.2698V19.9366ZM147.966 18.261H165.664V38.7599H186.817V18.261H204.516V6.14598H147.966V18.261ZM101.005 23.0596L84.4651 6.14598H61.1343V38.7599H82.0044V21.8463L98.5298 38.7599H121.872V6.14598H101.005V23.0596ZM368.379 13.0456C368.379 4.14775 360.506 0 343.921 0C326.031 0 318.483 4.75294 318.483 16.12H335.114C335.114 12.2063 337.863 10.8076 343.316 10.8076C348.625 10.8076 350.304 11.741 350.304 13.1854C350.304 15.0952 349.698 15.0952 336.316 18.2153C321.503 21.7093 316.905 28.6061 316.905 35.2687V38.7628H367.174V28.175H337.772C339.545 26.4965 344.574 26.0312 355.71 23.8931C363.115 22.5 368.379 19.9366 368.379 13.0456ZM206.762 38.7599H254.888V29.0314H226.987V26.2824H246.835V18.6321H226.987V15.8831H254.894V6.14598H206.762V38.7599ZM422 21.4296V32.3313H414.452V38.7599H397.265V32.3313H368.893V21.3239L389.252 1.29029H414.455V21.4182L422 21.4296ZM397.262 10.7134L386.414 21.4296H397.262V10.7134ZM317.598 22.4658C317.598 33.4132 310.702 38.7713 296.491 38.7713H257.592V6.14598H296.491C310.702 6.14598 317.598 11.5041 317.598 22.4658ZM296.959 22.4658C296.959 18.7833 294.907 17.0592 290.622 17.0592H278.23V27.8696H290.622C294.907 27.8582 296.959 26.134 296.959 22.4658Z" fill="black"></path>
        </svg>
        <div class="support">
          SUPPORT UKRAINE
        </div>
      </div>
    </a>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
export default {
  name: 'footerComponent',
  components: {
  },
  data() {
    return {
    }
  },
  mounted () {
  },
  computed: {
  },
  methods: {
  }
}
</script>
<style scoped lang="scss">
@import "src/assets/styles";
.footerWrap {
  display: flex;
  flex-direction: column;
  position: relative;
  background: #fff;
  box-shadow: 0px -1px 4px 0px #0000001A;
  padding-bottom: 20px;
  z-index: 10;
}
.support {
  font-size: 12px;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 400;
  letter-spacing: 1.5px;
  color: $black-text;
  transition: $ease05s;
  &:hover {
    color: $gold-text;
  }
}
.footerComponent {
  background: #fff;
  position: relative;
  z-index: 10;
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 14.89vw;
  font-family: Roboto, sans-serif;
  .footerLeft {
    .socialWrap {
      display: flex;
      gap: 20px
    }
    .instagram {
      height: 32px;
      width: 32px;
      border-radius: 50%;
      border: 1px solid $gold-text;
      display: flex;
      justify-content: center;
      align-items: center;
      img, svg, a {
        height: 20px;
        width: 20px;
      }
    }
  }
  .footerRight {
    .item {
      &::after {
        content: "\00a0\203A";
      }
    }
  }
  .footerLeft, .footerRight {
    display: flex;
    gap: 28px;
    align-items: center;
    a {
      font-size: 12px;
      text-transform: uppercase;
      text-decoration: none;
      font-weight: 400;
      letter-spacing: 1.5px;
      color: $black-text;
      transition: $ease05s;
      &:hover {
        color: $gold-text;
      }
    }
  }
}

@media screen and (min-width: 769px) and (max-width: 1440px) {
  .footerComponent {
    padding: 0 40px;
  }
}
@media screen and (max-width: 768px) {
  .footerComponent {
    padding: 40px 0;
    flex-direction: column;
    width: 100vw;
    height: auto;
    .footerLogo {
      display: none;
    }
    .footerLeft {
      flex-direction: column;
      .socialWrap {
        margin-bottom: 48px;
      }
      .instagram {
        border-radius: 50%;
        border: 1px solid $gold-text;
        img {
          height: 20px;
          width: 20px;
        }
      }
    }
    .footerRight {
      flex-direction: column;
    }
  }
}
</style>
