<template>
  <div class="documentation">
    <navbar-component/>
    <div class="content">
      <div class="sections">
<!--        <div class="sectionItem">-->
<!--          <img :src="require('/src/assets/svg/hideSection.svg')" alt="">-->
<!--          Documentation-->
<!--        </div>-->
        <div class="sectionItem" @click="changeSetting('showVideoBlock')">
          <img :src="require('/src/assets/svg/visible.svg')" alt="" v-if="sections.showVideoBlock">
          <img :src="require('/src/assets/svg/invisible.svg')" alt="" v-else>
          Video
        </div>
<!--        <div class="sectionItem">-->
<!--          <img :src="require('/src/assets/svg/hideSection.svg')" alt="">-->
<!--          Terms and Conditions-->
<!--        </div>-->
<!--        <div class="sectionItem">-->
<!--          <img :src="require('/src/assets/svg/hideSection.svg')" alt="">-->
<!--          Partners-->
<!--        </div>-->
<!--        <div class="sectionItem">-->
<!--          <img :src="require('/src/assets/svg/hideSection.svg')" alt="">-->
<!--          Settings-->
<!--        </div>-->
      </div>

    </div>
  </div>
</template>

<script>
import navbarComponent from "@/components/navbarComponent.vue";
import axios from "axios";
import {mapActions, mapGetters} from "vuex";
export default {
  name: 'documentationView',
  components: {
    navbarComponent
  },
  data() {
    return {
      sections: {}
    }
  },
  created() {
    this.getSections()
  },
  mounted () {
    // this.register()
  },
  computed: {
    ...mapGetters(['StateToken'])
  },
  methods: {
    changeSetting() {
      axios.put(`https://portal.baltecousa.com/api/settings`, {showVideoBlock: !this.sections.showVideoBlock},{
        headers: { authorization: `Bearer ${this.StateToken.access}`}
      }).then(resp => {
        this.getSections()
      })
    },
    getSections() {
      axios.get(`https://portal.baltecousa.com/api/settings`, {
        headers: { authorization: `Bearer ${this.StateToken.access}`}
      }).then(resp => {
        this.sections = resp.data
      })
    },
  }
}
</script>
<style scoped lang="scss">
@import "src/assets/styles";
.documentation {
  min-height: $pageHeight;
  display: flex;
  position: relative;
  .content {
    padding-left: 480px;
    padding-right: 288px;
    width: 100%;
    .sections {
      padding: 76px 115px;
    }
    .sectionItem {
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      gap: 32px;
      img {
        padding: 6px 13px;
        border: 1px solid $black-text;
        border-radius: 100px;
      }
    }
  }
}
</style>
