import Vue from 'vue'
import store from '../store/index';
import VueRouter from 'vue-router'
import loginView from "@/views/loginView.vue";
import documentationView from "@/views/documentationView.vue";
import videoView from "@/views/videoView.vue";
import usersView from "@/views/usersView.vue";
import sectionsView from "@/views/sectionsView.vue";
import signUpView from "@/views/signUpView.vue";
import signInView from "@/views/signInView.vue";
import forgotView from "@/views/forgotView.vue"
import userDocumentation from "@/views/userDocumentation.vue";
import userVideos from "@/views/userVideos.vue";
import mapView from "@/views/mapView.vue";
import usersApproveView from "@/views/usersApproveView.vue";
import userFormView from "@/views/userFormView.vue";
import changeEmailView from "@/views/changeEmailView.vue";
import changePasswordView from "@/views/changePasswordView.vue";
import aboutMeView from "@/views/aboutMeView.vue";
import myDocuments from "@/views/myDocuments.vue";
import userDocumentationAdminView from "@/views/userDocumentationAdminView.vue";



Vue.use(VueRouter)

const routes = [
  {
    path: '/admin-login',
    name: 'login',
    component: loginView,
    meta: { guest: true }
  },
  {
    path: '/sign-up',
    name: 'signup',
    component: signUpView,
    meta: { guest: true }
  },
  {
    path: '/forgot-password',
    name: 'forgot',
    component: forgotView,
    meta: { guest: true }
  },
  {
    path: '/documentation',
    name: 'documentation',
    component: userDocumentation,
    meta: {requiresUser: true},
  },
  {
    path: '/myDocuments',
    name: 'myDocuments',
    component: myDocuments,
    meta: {requiresUser: true},
  },
  {
    path: '/videos',
    name: 'videos',
    component: userVideos,
    meta: {requiresUser: true},
  },
  {
    path: '/sign-in',
    name: 'signin',
    component: signInView,
    meta: { guest: true }
  },
  {
    path: '/admin-documentation',
    name: 'admin-documentation',
    component: documentationView,
    meta: {requireAdmin: true},
  },
  {
    path: '/admin-video',
    name: 'video',
    component: videoView,
    meta: {requireAdmin: true},
  },
  {
    path: '/admin-users',
    name: 'users',
    component: usersView,
    meta: {requireAdmin: true},
  },
  {
    path: '/admin-approve',
    name: 'admin-approve',
    component: usersApproveView,
    meta: {requireAdmin: true},
  },
  {
    path: '/admin-sections',
    name: 'sections',
    component: sectionsView,
    meta: {requireAdmin: true},
  },
  {
    path: '/admin-map',
    name: 'admin-map',
    component: mapView,
    meta: {requireAdmin: true},
  },
  {
    path: '/settings-change-password',
    name: 'change-password',
    component: changePasswordView,
    meta: {requiresUser: true},
  },
  {
    path: '/settings-change-email',
    name: 'change-email',
    component: changeEmailView,
    meta: {requiresUser: true},
  },
  {
    path: '/settings-contact-support',
    name: 'contact',
    component: userFormView,
    meta: {requiresUser: true},
  },
  {
    path: '/settings-about-me',
    name: 'about',
    component: aboutMeView,
    meta: {requiresUser: true},
  },
  {
    path: '/userDocumentationAdmin/:id',
    name: 'userDocumentationAdmin',
    component: userDocumentationAdminView,
    meta: {requireAdmin: true},
  },
  {
    path: "*",
    name: '404',
    redirect: store.getters.StateRoles? (store.getters.StateRoles[0] === 'super_user' || store.getters.StateRoles[0] === 'administrator' ) ? '/admin-documentation' : '/sign-in' : '/sign-in',
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requireAdmin)) {
    if (store.getters.StateRoles === null) {
      next('/admin-login')
    }
    else if (store.getters.StateRoles[0] === "super_user") {
      next();
      return;
    }
    else if (store.getters.StateRoles[0] === "administrator") {
      next();
      return;
    }
      next();
  }
  if (to.matched.some((record) => record.meta.requiresUser)) {
    if (store.getters.StateRoles === null) {
      next('/sign-in')
      return;
    }
    else if (store.getters.StateRoles[0] === "user") {
      next();
      return;
    }
    next('/sign-in')
  }
  if (to.matched.some((record) => record.meta.guest)) {
    if (!store.getters.isAuthenticated) {
      next()
    }
    else if (store.getters.StateRoles[0] === "super_user" || store.getters.StateRoles[0] === "administrator") {
      next('/admin-documentation')
    }
    else {
      next('/documentation')
    }
    // next('/admin-documentation')
  }
});

export default router