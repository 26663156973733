<template>
  <div class="signUp">
    <div class="content">
      <div class="left">
        <div class="leftWrap">
          <h1>
            FORGOT PASSWORD?
          </h1>
        </div>
      </div>
      <div class="right">
        <div class="hint" v-if="hint">
          <div class="hintLeft">
            <img :src="require('/src/assets/svg/hint.svg')" alt="">
          </div>
          <div class="hintRight">
            <div class="top">
              Code sent!
            </div>
            <div class="bot">
              Please check yor e-mail, and enter code from letter.
            </div>
          </div>
        </div>
        <div class="form">
          <div class="inputLabel">
            Enter the email address associated with your account:
          </div>
          <input type="text" placeholder="name@mail.com" v-model="email" :disabled="emailSent">
          <div class="inputLabel" v-if="emailSent">
            Verification code:
          </div>
          <input type="text" placeholder="00000" v-model="code" v-if="emailSent">
          <div class="inputLabel" v-if="emailSent">
            Enter new password:
          </div>
          <input type="password" placeholder="********" v-model="password"   v-if="emailSent">
          <div class="inputLabel" v-if="emailSent">
            Confirm new password:
          </div>
          <input type="password" placeholder="********" v-model="passwordConfirm"  v-if="emailSent">
          <div class="registration" @click="refreshPassword" v-if="emailSent">
            Refresh password
          </div>
          <div class="registration" @click="sendCode" v-else>
            Send verification code
          </div>
          <div class="login" @click="$router.push('/sign-in')">
            login
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {mapActions, mapGetters} from "vuex";
export default {
  name: 'signup',
  components: {
  },
  data() {
    return {
      email: '',
      code: '',
      password: '',
      passwordConfirm: '',
      hint: false,
      emailSent: false

    }
  },
  created() {
  },
  mounted () {
    // this.register()
  },
  computed: {
    ...mapGetters(['StateToken'])
  },
  methods: {
    refreshPassword() {
      if (this.password !== this.passwordConfirm)
        return
      axios.post(`https://portal.baltecousa.com/api/auth/password-recovery/${this.code}`, {
        "password": this.password,
        "passwordConfirmation": this.passwordConfirm
      }).then( () => {
        this.$router.push('sign-in')
        this.hint = false
        // this.email = ''
        this.emailSent=true
      })
    },
    sendCode() {
      if (this.email.length < 3)
        return
      axios.post('https://portal.baltecousa.com/api/auth/forgot-password', {
        "email": this.email,
      }).then( () => {
        this.hint = true
        // this.email = ''
        this.emailSent=true
      })
    }
  }
}
</script>
<style scoped lang="scss">
@import "src/assets/styles";
.signUp {
  min-height: $pageHeight;
  display: flex;
  position: relative;
  .left {
    height: calc(100vh - 60px - 120px);
    width: 50vw;
    background: #727272;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 65px;
    background-size: cover;
    background: url("@/assets/img/bg.png") no-repeat;
    background-size: cover;
    overflow: hidden;

    .leftWrap {
      padding: 65px 95px 65px 286px;
      color: $light-silver;
      h1 {
        margin-top: 0;
        font-size: 40px;
        font-weight: 300;
        margin-bottom: 32px;
      }
      h2 {
        font-size: 16px;
        font-weight: 300;
        margin-bottom: 32px;
      }
      img {

      }
    }
  }
  .right {
    height: $pageHeight;
    width: 50vw;
    padding: 65px 95px;
    .hint {
      display: flex;
      align-items: center;
      background: $light-silver;
      padding: 16px 24px;
      border-radius: 8px;
      width: fit-content;
      margin-bottom: 16px;
      .hintLeft {
        margin-right: 12px;
      }
      .hintRight {
        font-size: 16px;
        .top {
          font-weight: 600;
        }
        .bot {
          font-weight: 400;
        }
      }
    }
    .form {
      width: 356px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .inputLabel {
      width: 365px;
      color: $black-text;
      font-size: 16px;
      font-weight: $font-light;
      letter-spacing: 0;
      margin-bottom: 12px;
    }
    input {
      width: calc(100% - 16px * 2);
      font-size: 16px;
      font-weight: $font-regular;
      margin-bottom: 12px;
      padding: 12px 16px;
      border-radius: 4px;
      outline: none;
      border: 1px solid $silver;
      color: $black-text;
      &::placeholder {
        color: $dark72;
      }
      &:hover {
        border: 1px solid $gold-text;
      }
      &:focus {
        border: 1px solid $gold-text;
        color: $black-text;
      }
    }
    .login {
      width: 100%;
      background: $black-text;
      color: #fff;
      border-radius: 139px;
      padding: 12px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      text-transform: uppercase;
    }
    .registration {
      width: 100%;
      background: #fff;
      color: $black-text;
      border: 1px solid $black-text;
      border-radius: 139px;
      padding: 12px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      text-transform: uppercase;
      margin-bottom: 16px;
    }
    a {
      color: $dark72;
      text-decoration: none;
      margin-top: 16px;
      font-size: 12px;
    }
  }

  .content {
    display: flex;
    position: fixed;
  }
}
</style>
