<template>
  <div class="signUp">
    <div class="content">
      <div class="left">
        <user-navbar-component/>
      </div>
      <div class="right">
        <div class="contentWrap">

          <div class="hint" v-if="hint">
            <div class="hintLeft">
              <img :src="require('/src/assets/svg/hint.svg')" alt="">
            </div>
            <div class="hintRight">
              <div class="top">
                Success!
              </div>
              <div class="bot">
                {{ hintMessage }}
              </div>
            </div>
          </div>
          <div class="form">
            <div class="inputLabel">
              First name:
            </div>
            <input type="text" placeholder="First name" v-model="firstName">
            <div class="inputLabel">
              Last name:
            </div>
            <input type="text" placeholder="Last name" v-model="lastName">
            <div class="inputLabel">
              Phone number:
            </div>
            <input type="text" placeholder="+123 0000000" v-model="phone">
            <div class="createBtn" @click="updateProfile">
              update info
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {mapActions, mapGetters, mapMutations} from "vuex";
import userNavbarComponent from "@/components/userNavbarComponent.vue";
export default {
  name: 'signup',
  components: {
    userNavbarComponent
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      phone: '',
      hint: false,
      hintMessage: ''
    }
  },
  created() {
  },
  mounted () {
  },
  computed: {
    ...mapGetters(['StateToken'])
  },
  methods: {
    ...mapMutations(['logout']),
    logoutUser() {
      this.logout()
      this.$router.push('/sign-in')
    },
    updateProfile() {
      axios.put('https://portal.baltecousa.com/api/profile', {
        firstName: this.firstName,
        lastName: this.lastName,
        phone: this.phone,
      }, {
        headers: { authorization: `Bearer ${this.StateToken.access}`}
      }).then(resp => {
          console.log(resp)
          this.hintMessage = 'Data updated successfully'
          this.showError = true
          this.hint = true
          // this.message = error.response.data.message
      }).catch(error => {
        this.hintMessage = error.response.data.Message
        this.showError = true
        this.hint = true
        this.message = error.response.data.message
      })
    }
  }
}
</script>
<style scoped lang="scss">
@import "src/assets/styles";
.signUp {
  min-height: $pageHeight;
  display: flex;
  position: relative;
  .left {
    height: $pageHeight;
    width: 50vw;
    background: $dark72;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 65px;
    background: url("@/assets/img/bg.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
  .right {
    height: calc($pageHeight - 130px) ;
    width: calc(50vw - 95px * 2);
    padding: 65px 95px;
    overflow: auto;

    .hint {
      display: flex;
      align-items: center;
      background: $light-silver;
      padding: 16px 24px;
      border-radius: 8px;
      width: fit-content;
      margin-bottom: 16px;
      .hintLeft {
        margin-right: 12px;
      }
      .hintRight {
        font-size: 16px;
        .top {
          font-weight: 600;
        }
        .bot {
          font-weight: 400;
        }
      }
    }
    .contentWrap {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 16px;
      margin-bottom: 16px;
      .contentItem {
        text-align: center;
        .imageWrap {
          padding: 70px 50px;
          background: #D9D9D9;
          border-radius: 12px;
          margin-bottom: 12px;
        }
      }
    }



    .form {
      width: 356px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .inputLabel {
      width: 365px;
      color: $black-text;
      font-size: 16px;
      font-weight: $font-light;
      letter-spacing: 0;
      margin-bottom: 12px;
    }
    input, textarea {
      width: calc(100% - 16px * 2);
      font-size: 16px;
      font-weight: $font-regular;
      margin-bottom: 12px;
      padding: 12px 16px;
      border-radius: 4px;
      outline: none;
      border: 1px solid $silver;
      color: $black-text;
      &::placeholder {
        color: $dark72;
      }
      &:hover {
        border: 1px solid $gold-text;
      }
      &:focus {
        border: 1px solid $gold-text;
        color: $black-text;
      }
    }
    .login {
      width: 100%;
      background: $black-text;
      color: #fff;
      border-radius: 139px;
      padding: 12px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      text-transform: uppercase;
    }
    .registration {
      width: 100%;
      background: #fff;
      color: $black-text;
      border: 1px solid $black-text;
      border-radius: 139px;
      padding: 12px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      text-transform: uppercase;
      margin-top: 16px;
    }
    a {
      color: $dark72;
      text-decoration: none;
      margin-top: 16px;
      font-size: 12px;
    }
    .createBtn {
      width: 100%;
      background: $black-text;
      color: #fff;
      border-radius: 139px;
      padding: 12px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      text-transform: uppercase;
    }
  }

  .content {
    display: flex;
    position: fixed;
  }
}
</style>
