<template>
  <div class="documentation">
    <navbar-component/>
    <div class="content">

      <div class="createWrap" v-if="createWindow">
        <div class="createWindow">
          <div class="close">
            <img :src="require('/src/assets/svg/closeBlack.svg')" alt="" @click="createWindow=false">
          </div>
          <div class="createWindowContent">
            <div class="inputLabel">
              File title:
            </div>
            <input type="text" placeholder="Name" v-model="editFileObj.title">
            <div class="inputLabel">
              File Description:
            </div>
            <input type="text" placeholder="Description" v-model="editFileObj.description">
<!--            <div class="inputLabel">-->
<!--              Video Description:-->
<!--            </div>-->
<!--            <textarea name="" id="" cols="30" rows="5" v-model="newItemLink">-->
<!---->
<!--            </textarea>-->
            <div class="createBtn" @click="editFile">
              Save
            </div>
          </div>
        </div>
      </div>
      <div class="tableWrap">
        <div class="table">
          <div class="tableTopActions">
            <div class="tableTopActionsLeft">
<!--              <div class="addNew">-->
<!--                <img :src="require('/src/assets/svg/addFile.svg')" alt="">-->
<!--                add new-->
<!--              </div>-->
<!--              <div class="export">-->
<!--                <img :src="require('/src/assets/svg/exportFile.svg')" alt="">-->
<!--                export-->
<!--                <img :src="require('/src/assets/svg/arrowRight.svg')" alt="">-->
<!--              </div>-->
            </div>
            <div class="search">
              <input type="text" placeholder="Searching..." v-model="filter" @input="debounce">
            </div>
            <div class="results">
              Results

              <div class="customSelect" @click="customSelect = !customSelect">
                {{activeSelectItem}}
                <img :src="require('/src/assets/svg/arrowDownBlack.svg')" alt="">
                <div class="customSelectWindow" v-if="customSelect">
                  <div class="customSelectItem" @click="setSelectItem(15)">15</div>
                  <div class="customSelectItemDivider"></div>
                  <div class="customSelectItem" @click="setSelectItem(25)">25</div>
                  <div class="customSelectItemDivider"></div>
                  <div class="customSelectItem" @click="setSelectItem(50)">50</div>
                  <div class="customSelectItemDivider"></div>
                  <div class="customSelectItem" @click="setSelectItem(100)">100</div>
                </div>
              </div>
            </div>
          </div>
          <div class="tableData">
            <table>
              <tr class="tableHeader">
<!--                <th>-->
<!--                  Check-->
<!--                </th>-->
                <th>
                  Action
                </th>
                <th>
                  File title
                </th>
                <th>
                  File description
                </th>
                <th class="fileLink">
                  File link
                </th>
                <th>
                  Last update
                </th>
              </tr>
              <tr v-for="file in files" :key="file.id">
<!--                <td>-->
<!--                  <input type="checkbox">-->
<!--                </td>-->
                <td>
                  <div class="tableActions">
                    <img :src="require('/src/assets/svg/visible.svg')" alt="" class="clickable" v-if="file.isVisible" @click="changeFileVisibility(file)">
                    <img :src="require('/src/assets/svg/invisible.svg')" alt="" class="clickable" v-else @click="changeFileVisibility(file)">
                    <img :src="require('/src/assets/svg/actionsDivider.svg')" alt="">
                    <img :src="require('/src/assets/svg/edit.svg')" alt="" class="clickable" @click="openEditMode(file)">
                    <img :src="require('/src/assets/svg/actionsDivider.svg')" alt="">
                    <img :src="require('/src/assets/svg/delete.svg')" alt="" class="clickable" @click="deleteFile(file)">
                  </div>
                </td>
                <td>
                  {{ file.title }}
                </td>
                <td>
                  {{file.description}}
                </td>
                <td class="fileLink" @click="downloadFile(file.fileUrl, file.title, file.fileExtension)">
                    {{file.fileUrl}}
                </td>
                <td>
                  {{getHumanDate(file.updatedAt ? file.updatedAt : file.createdAt)}}
                </td>
              </tr>
            </table>
          </div>
          <div class="dragAndDrop" @dragover="dragover" @drop="drop">
            <input type="file" id="assetsFieldHandle" @change="onChange" ref="file" accept=".pdf,.jpg,.jpeg,.png">
            <label for="assetsFieldHandle">
              <div class="dragAndDropBody">
                <img :src="require('/src/assets/svg/dragAndDrop.svg')" alt="" v-if="!file">
                <span v-if="!file">Drag & Drop</span>
                <span v-if="file" class="selectedFile" @click="remove">
                  {{file.name}}
                  <img :src="require('/src/assets/svg/close.svg')" alt="">
                </span>
                <span class="uploadFileBtn" v-if="file" @click="sendFile">
                  <img :src="require('/src/assets/svg/uploadWhite.svg')" alt="">
                  Upload file
                </span>
                <br>
              </div>
            </label>
          </div>
          <div class="tableBotActions">
            <div class="delete" style="opacity: 0">
              <img :src="require('/src/assets/svg/deleteBig.svg')" alt="">
              DELETE SELECTED DOCUMENTS
            </div>
            <div class="tableBotActionsRight">
              <div class="prev" @click="goPrev" v-if="activePage > 1">
                <img :src="require('/src/assets/svg/arrowRight.svg')" alt="">
                PREV
              </div>
              <div class="pagination">
                <div class="pageItem firstPage" v-if="firstPage !== activePage" @click="goToPage(1)">
                  {{firstPage}}
                </div>
                <div class="pageItem"  v-if="activePage > 1 && activePage !== 1">
                  ...
                </div>
                <div class="pageItem activePage">
                  {{activePage}}
                </div>
                <div class="pageItem"  v-if="activePage < lastPage ">
                  ...
                </div>
                <div class="pageItem lastPage" v-if="lastPage !== activePage"  @click="goToPage(lastPage)">
                  {{lastPage}}
                </div>
              </div>

              <div class="next" @click="goNext" v-if="activePage !== pages">
                NEXT
                <img :src="require('/src/assets/svg/arrowRight.svg')" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navbarComponent from "@/components/navbarComponent.vue";
import axios from "axios";
import {mapActions, mapGetters} from "vuex";
export default {
  name: 'documentationView',
  components: {
    navbarComponent
  },
  data() {
    return {
      customSelect: false,
      activeSelectItem: 15,
      files: [],
      file: null,
      activePage: 1,
      firstPage: 1,
      lastPage: null,
      pages: 1,
      filter: '',
      timeout: null,
      createWindow: false,
      editFileObj: {},
    }
  },
  created() {
    this.getDocumentations()
  },
  mounted () {
    // this.register()
  },
  computed: {
    ...mapGetters(['StateToken'])
  },
  methods: {
    openEditMode(file) {
      this.editFileObj = file
      this.createWindow = true
    },
    editFile() {
      axios.put(`https://portal.baltecousa.com/api/documentations/${this.editFileObj.id}`, this.editFileObj, {
        headers: { authorization: `Bearer ${this.StateToken.access}`, 'Content-Type': 'multipart/form-data'}
      }).then( () => {
        this.getDocumentations()
        this.createWindow = false
      })
    },
    goToPage(page) {
      this.activePage = page
      this.getDocumentations()
    },
    getHumanDate (date) {
      return new Date(date).toLocaleString()
    },
    debounce(e) {
      if (this.timeout)
        clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.filter = String(e.target.value)
        this.getDocumentations()
      }, 500); // delay
    },

    goNext() {
      if (this.activePage < this.pages)
      this.activePage++
      this.getDocumentations()
    },
    goPrev() {
      if (this.activePage > 1)
        this.activePage--
      this.getDocumentations()
    },
    changeFileVisibility(file) {
      file.isVisible = !file.isVisible
      file.file = null
      axios.put(`https://portal.baltecousa.com/api/documentations/${file.id}`, file, {
        headers: { authorization: `Bearer ${this.StateToken.access}`, 'Content-Type': 'multipart/form-data'}
      }).then( () => {
        this.getDocumentations()
      })
    },
    deleteFile(file) {
      axios.delete(`https://portal.baltecousa.com/api/documentations/${file.id}`, {
        headers: { authorization: `Bearer ${this.StateToken.access}`}
      }).then( () => {
        this.getDocumentations()
      })
    },
    downloadFile(url, filename, fileExtension) {
      const link = document.createElement("a");
      // If you don't know the name or want to use
      // the webserver default set name = ''
      link.setAttribute('download', `${filename}${fileExtension}`);
      link.href = url;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      link.remove();

    },
    sendFile(e) {
      e.preventDefault()
      const formData = new FormData();
      formData.append('file', this.file);
      const headers = { 'Content-Type': 'multipart/form-data',  'authorization': `Bearer ${this.StateToken.access}` };
      axios.post('https://portal.baltecousa.com/api/documentations', {
        title: 'Change title',
        description: 'Change description',
        file: this.file,
        isVisible: false,
      }, {headers}).then(resp => {
        this.getDocumentations()
        this.file = null
      })
    },
    onChange() {
      this.file = this.$refs.file.files[0]
    },
    remove(e) {
      e.preventDefault()
      this.file = null
    },
    dragover(event) {
      event.preventDefault();
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
    },
    getDocumentations() {
      axios.get(`https://portal.baltecousa.com/api/documentations/all?Page=${this.activePage}&PageSize=${this.activeSelectItem}&Sorts=-createdAt&Filters=title@=*${this.filter}`, {
        headers: { authorization: `Bearer ${this.StateToken.access}`}
      }).then(resp => {
        this.files = resp.data.data
        this.pages = resp.data.pages
        this.lastPage = this.pages
      })
    },
    setSelectItem(num) {
      this.activeSelectItem = num
      this.activePage = 1
      this.getDocumentations()
    }
  }
}
</script>
<style scoped lang="scss">
@import "src/assets/styles";
.documentation {
  min-height: $pageHeight;
  display: flex;
  position: relative;

  .createWrap {
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, .4);
    display: flex;
    justify-content: center;
    align-items: center;
    .createWindow {
      border-radius: 12px;
      display: flex;
      width: 360px;
      padding: 26px 32px;
      background: $light-silver;
      flex-direction: column;
      .close {
        display: flex;
        justify-content: flex-end;
        img {
          cursor: pointer;
        }
      }
      .createWindowContent {
        .inputLabel {
          width: 365px;
          color: $black-text;
          font-size: 16px;
          font-weight: $font-light;
          letter-spacing: 0;
          margin-bottom: 12px;
        }
        input, textarea {
          width: calc(100% - 18px * 2);
          font-size: 16px;
          font-weight: $font-regular;
          margin-bottom: 12px;
          padding: 12px 16px;
          border-radius: 4px;
          outline: none;
          border: 1px solid $silver;
          color: $black-text;
          &::placeholder {
            color: $dark72;
          }
          &:hover {
            border: 1px solid $gold-text;
          }
          &:focus {
            border: 1px solid $gold-text;
            color: $black-text;
          }
        }
        .createBtn {
          background: $black-text;
          color: #fff;
          border-radius: 139px;
          padding: 12px 0;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }
    }
  }
  .content {
    padding-left: 480px;
    padding-right: 288px;
    width: 100%;
    .tableWrap {
      padding: 65px 0 65px 95px;
      .table {
        .tableTopActions {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .tableTopActionsLeft {
            display: flex;
            gap: 12px;
            .addNew {
              height: 20px;
              text-transform: uppercase;
              cursor: pointer;
              padding: 9px 20px;
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 8px;
              background: $black-text;
              border-radius: 139px;
              color: #fff;
              font-size: 12px;
              font-weight: 400;
            }
            .export {
              height: 18px;
              text-transform: uppercase;
              cursor: pointer;
              padding: 9px 20px;
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 8px;
              border: 1px solid $black-text;
              background: #fff;
              border-radius: 139px;
              color: $black-text;
              font-size: 12px;
              font-weight: 400;
            }
          }
          .search {
            input {
              width: calc(256px - 12px * 2);
              border: 1px solid $silver;
              border-radius: 4px;
              outline: none;
              background: url("@/assets/svg/search.svg") $light-silver no-repeat scroll;
              background-position: right;
              background-position-x: calc(100% - 10px);
              padding: 12px 35px 12px 8px;
              font-size: 12px;
              &::placeholder {
                font-size: 12px;
              }
            }
          }
          .results {
            display: flex;
            align-items: center;
            gap: 12px;
            .customSelect {
              z-index: 1;
              position: relative;
              display: flex;
              gap: 10px;
              height: 12px;
              padding: 12px 18px;
              justify-content: center;
              align-items: center;
              background: $light-silver;
              border: 1px solid $silver;
              border-radius: 4px;
              img {
                height: 6px;
                width: 8px;
              }
              .customSelectWindow {
                z-index: 1;
                position: absolute;
                top: 100%;
                width: 100%;
                justify-content: center;
                align-items: center;
                background: $light-silver;
                border: 1px solid $silver;
                .customSelectItem {
                  padding: 10px;
                  text-align: right;
                }
                .customSelectItemDivider {
                  width: 100%;
                  height: 1px;
                  background: $silver;
                }
              }
            }
          }
        }
        .tableData {
          table {
            margin-top: 16px;
            font-family: Roboto, sans-serif;
            border-collapse: collapse;
            border: none;
            width: 100%;
            th {
              color: #fff;
              height: 38px;
              text-align: left;
              padding-left: 16px;
              font-size: 12px;
              font-weight: 500;
            }
            td {
              height: 38px;
              color: $black-text;
              padding-left: 16px;
              text-align: left;
              .tableActions {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 10px;
                height: 22px;
                border-radius: 139px;
                border: 1px solid $black-text;
                width: min-content;
                padding: 0 14px;
                .clickable {
                  cursor: pointer;
                }
              }
            }
            tr {
              .fileLink {
                cursor: pointer;
              }
            }
            .fileLink {
              max-width: 150px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
            .tableHeader {
              background: $dark72;
            }
          }
        }
      }
      .dragAndDrop {
        border: 1px dashed $dark72;
        border-radius: 8px;
        cursor: pointer;
        input {
          display: none;
        }
        label {
          width: 100%;
          height: 100%;
        }
        .dragAndDropBody {
          width: 100%;
          padding: 12px 0;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          cursor: pointer;
          .selectedFile {
            background: $gold-text;
            border-radius: 228px;
            color: #fff;
            display: flex;
            padding: 7px 16px;
            gap: 5px;
          }
          .uploadFileBtn {
            background: $black-text;
            border-radius: 228px;
            color: #fff;
            display: flex;
            padding: 7px 16px;
            gap: 5px;
          }
        }
      }
      .tableBotActions {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .delete {
          background: $red;
          padding: 12px 16px;
          border-radius: 139px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 7px;
          color: #fff;
          font-size: 12px;
        }
        .tableBotActionsRight {
          display: flex;
          gap: 16px;
          .pagination {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            .pageItem {
              cursor: pointer;
              padding: 11px 12px;
              border-radius: 4px;
              font-weight: 600;
              color: $black-text;
            }
            .activePage {
              color: #fff;
              background: $dark72;
            }
          }
          .prev, .next {
            cursor: pointer;
            padding: 12px 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 9px;
            border-radius: 139px;
            border: 1px solid $black-text;
          }
          .prev {
            img {
              transform: rotate(180deg);
            }
          }
          .next {

          }
        }
      }
    }
  }
}
</style>
