<template>
  <div class="signUp">
    <div class="content">
      <div class="left">
        <div class="leftWrap">
          <h1>
            REGISTRATION
          </h1>
          <h2>
            Welcome to the Balteco dealer portal. Let's start!
          </h2>
<!--          <img :src="require('/src/assets/img/register.png')" alt="">-->
        </div>

      </div>
      <div class="right">
        <div class="hint" v-if="hint">
          <div class="hintLeft">
            <img :src="require('/src/assets/svg/hint.svg')" alt="">
          </div>
          <div class="hintRight">
            <div class="top">
              Thank you for registration!
            </div>
            <div class="bot">
              After reviewing your profile, we will send message on your email!
            </div>
          </div>
        </div>
        <div class="hint" v-if="hintValidation">
          <div class="hintLeft">
            <img :src="require('/src/assets/svg/hint.svg')" alt="">
          </div>
          <div class="hintRight">
            <div class="top">
              Validation error!
            </div>
            <div class="bot">
              {{hintValidationMessage}}
            </div>
          </div>
        </div>
        <div class="form">
          <div class="inputLabel">
            E-mail:
          </div>
          <input type="text" placeholder="name@mail.com" v-model="email">
          <div class="inputLabel">
            Company name:
          </div>
          <input type="text" placeholder="Company" v-model="company">
          <div class="inputLabel">
            Password:
          </div>
          <input type="password" placeholder="*******" v-model="password">
          <div class="inputLabel">
            Repeat password:
          </div>
          <input type="password" placeholder="*******" v-model="confirm">
          <div class="createBtn" @click="register">
            registration
          </div>
          <a  @click="$router.push('/sign-in')" style="cursor: pointer;">
            I have an account
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {mapActions, mapGetters} from "vuex";
export default {
  name: 'signup',
  components: {
  },
  data() {
    return {
      email: '',
      company: '',
      password: '',
      confirm: '',
      hint: false,
      hintValidation: false,
      hintValidationMessage: '',
    }
  },
  created() {
  },
  mounted () {
    // this.register()
  },
  computed: {
    ...mapGetters(['StateToken'])
  },
  methods: {
    doRegister() {
      axios.post('https://portal.baltecousa.com/api/auth/sign-up', {
        "companyName": this.company,
        "email": this.email,
        "password": this.password,
        "passwordConfirmation": this.confirm
      }).then( () => {
        this.hint = true
        this.company = ''
        this.email = ''
        this.password = ''
        this.confirm = ''
      }).catch(e => {
        console.log(e)
        this.hintValidation = true
        this.hintValidationMessage = e.response.data.Errors.Email[0]
      })
    },
    register() {
      const reg = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}")
      const regMail = new RegExp("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$")
      if (!regMail.test(this.email)) {
        this.hintValidation = true
        this.hintValidationMessage = 'Wrong email type'
        return
      }
      if (this.company.length < 3) {
        this.hintValidation = true
        this.hintValidationMessage = 'Enter company name'
        return
      }
      if (!reg.test(this.password)) {
        this.hintValidation = true
        this.hintValidationMessage = 'Password must be minimum eight characters, \n at least one uppercase letter, one lowercase letter and one number'
        return
      }
      if (this.password !== this.confirm) {
        this.hintValidation = true
        this.hintValidationMessage = 'Passwords does not match'
        return
      }
      this.hintValidationMessage = ''
      this.hintValidation = false
      try {
        this.doRegister()
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>
<style scoped lang="scss">
@import "src/assets/styles";
.signUp {
  min-height: $pageHeight;
  display: flex;
  position: relative;
  .left {
    height: calc(100vh - 60px - 120px);
    width: 50vw;
    background: #727272;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-size: cover;
    background: url("@/assets/img/bg.png") no-repeat;
    background-size: cover;
    overflow: hidden;
    .leftWrap {
      padding: 65px 95px 65px 286px;
      color: $light-silver;
      h1 {
        margin-top: 0;
        font-size: 40px;
        font-weight: 300;
        margin-bottom: 32px;
      }
      h2 {
        font-size: 16px;
        font-weight: 300;
        margin-bottom: 32px;
      }
      img {

      }
    }
  }
  .right {
    height: $pageHeight;
    width: 50vw;
    padding: 65px 95px;
    .hint {
      max-width: 350px;
      display: flex;
      align-items: center;
      background: $light-silver;
      padding: 16px 24px;
      border-radius: 8px;
      width: fit-content;
      margin-bottom: 16px;
      .hintLeft {
        margin-right: 12px;
      }
      .hintRight {
        font-size: 16px;
        .top {
          font-weight: 600;
        }
        .bot {
          font-weight: 400;
        }
      }
    }
    .form {
      width: 356px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .inputLabel {
      width: 365px;
      color: $black-text;
      font-size: 16px;
      font-weight: $font-light;
      letter-spacing: 0;
      margin-bottom: 12px;
    }
    input {
      width: calc(100% - 16px * 2);
      font-size: 16px;
      font-weight: $font-regular;
      margin-bottom: 12px;
      padding: 12px 16px;
      border-radius: 4px;
      outline: none;
      border: 1px solid $silver;
      color: $black-text;
      &::placeholder {
        color: $dark72;
      }
      &:hover {
        border: 1px solid $gold-text;
      }
      &:focus {
        border: 1px solid $gold-text;
        color: $black-text;
      }
    }
    .createBtn {
      width: 100%;
      background: $black-text;
      color: #fff;
      border-radius: 139px;
      padding: 12px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      text-transform: uppercase;
    }
    a {
      color: $dark72;
      text-decoration: none;
      margin-top: 16px;
      font-size: 12px;
    }
  }

  .content {
    display: flex;
    position: fixed;
  }
}
</style>
