<template>
  <div class="login">
    <div class="formWrap">
      <div class="inputLabel">
        E-mail:
      </div>
      <input v-model="form.login" placeholder="name@email.com"/>
      <div class="inputLabel">
        Password:
      </div>
      <input type="password" v-model="form.password" placeholder="********"/>
      <div class="loginButton" @click="login">
        LogIn
      </div>
<!--      <a href="" class="forgotPassword">-->
<!--        forgot password?-->
<!--      </a>-->
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
export default {
  name: 'LoginView',
  components: {
  },
  data() {
    return {
      form: {
        login: "",
        password: "",
      },
      message: '',

      showError: false
    }
  },
  mounted () {
    // this.register()
  },
  computed: {
  },
  methods: {
    ...mapActions(["LogIn"]),
    async login() {
      const User = {
        login: this.form.login,
        password: this.form.password
      };
      try {
        await this.LogIn(User);
        await this.$router.push("/admin-documentation");
      } catch (error) {
        this.showError = true
        this.message = error.response.data.message
      }
    },
  }
}
</script>
<style scoped lang="scss">
@import "src/assets/styles";
.login {
  min-height: $pageHeight;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $black-text;
  font-family: Roboto, sans-serif;
  .formWrap {
    .inputLabel {
      width: 365px;
      color: #fff;
      font-size: 16px;
      font-weight: $font-light;
      letter-spacing: 0;
      margin-bottom: 12px;
    }
    input {
      width: calc(100% - 18px * 2);
      font-size: 16px;
      font-weight: $font-regular;
      margin-bottom: 12px;
      padding: 12px 16px;
      border-radius: 4px;
      outline: none;
      border: 1px solid $silver;
      color: $black-text;
      &::placeholder {
        color: $dark72;
      }
      &:hover {
        border: 1px solid $gold-text;
      }
      &:focus {
        border: 1px solid $gold-text;
        color: $black-text;
      }
    }
    .loginButton {
      border-radius: 139px;
      padding: 12px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      color: #fff;
      background: $gold-text;
      text-transform: uppercase;
      margin-top: 4px;
      cursor: pointer;
    }
    .forgotPassword {
      display: flex;
      margin-top: 16px;
      font-weight: 400;
      color: $dark72;
      font-size: 12px;
      text-decoration: none;
      justify-content: center;

    }
  }
}
</style>
