<template>
  <div class="links">
    <h1>User cabinet</h1>
    <div class="link" :class="{active : $route.path === '/documentation'}" @click="$router.push('/documentation')">
      Documentation
    </div>
    <div class="link" :class="{active : $route.path === '/videos'}" @click="$router.push('/videos')">
      Video Digest
    </div>
    <div class="link" :class="{active : $route.path === '/myDocuments'}" @click="$router.push('/myDocuments')">
      My Documents
    </div>
<!--    <div class="link" :class="{active : $route.path === '/contact'}" @click="$router.push('/contact')">-->
<!--      Contact Form-->
<!--    </div>-->
    <div class="link" :class="{active : $route.path.includes('/settings')}" @click="$router.push('/settings-change-password')">
      Settings
      <div class="linkSubItem" :class="{active : $route.path === '/settings-change-password'}" @click="$router.push('/settings-change-password')" v-if="$route.path.includes('/settings')">
          Change Password
      </div>
<!--      <div class="linkSubItem" :class="{active : $route.path === '/settings-change-email'}" @click="$router.push('/settings-change-email')" v-if="$route.path.includes('/settings')">-->
<!--          Change E-mail-->
<!--      </div>-->
      <div class="linkSubItem" :class="{active : $route.path === '/settings-contact-support'}" @click="$router.push('/settings-contact-support')" v-if="$route.path.includes('/settings')">
          Contact Support
      </div>
      <div class="linkSubItem" :class="{active : $route.path === '/settings-about-me'}" @click="$router.push('/settings-about-me')" v-if="$route.path.includes('/settings')">
          About Me
      </div>
    </div>
    <div class="link" @click="logoutUser">
      Sign Out
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import axios from "axios";
export default {
  name: 'navbarComponent',
  components: {
  },
  data() {
    return {
      sections: {}
    }
  },
  created() {
    this.getSections()
  },
  mounted () {
  },
  computed: {
    ...mapGetters(['StateToken']),
  },
  methods: {
    ...mapActions(['LogOut']),
    getSections() {
      axios.get(`https://portal.baltecousa.com/api/settings`, {
        headers: { authorization: `Bearer ${this.StateToken.access}`}
      }).then(resp => {
        this.sections = resp.data
      })
    },
    logoutUser() {
      this.LogOut()
      this.$router.push('/sign-in')
    },
  }
}
</script>
<style scoped lang="scss">
@import "src/assets/styles";

.links {
  .link {
    margin-top: 32px;
    color: $light-silver;
    font-size: 16px;
    cursor: pointer;
  }
  .linkSubItem {
    margin-top: 16px;
    margin-left: 16px;
    color: $light-silver;
    font-size: 16px;
    cursor: pointer;
  }
  .active {
    color: $gold-text;
  }
}
h1 {
  text-transform: uppercase;
  font-size: 40px;
  font-weight: 300;
  margin: 0;
  padding: 0;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .links {
    width: calc(100% - 16px * 2);
    padding: 40px 16px;
    h1 {
      font-size: 25px;
    }
    .link {
      margin-top: 16px;
    }
  }
}
</style>
