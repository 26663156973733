<template>
  <div id="app">
    <header-component/>
    <router-view style="position:relative;"/>
    <footer-component/>
  </div>
</template>


<script>
import headerComponent from "@/components/headerComponent.vue";
import footerComponent from "@/components/footerComponent.vue";
export default {
  components: {
    headerComponent, footerComponent
  },
  data() {
    return {
    }
  },
  mounted () {
  },
  computed: {
  },
  methods: {
  }
}
</script>


<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik:wght@300;400;500&display=swap');
html, body {
  padding: 0;
  margin: 0;
}
#app {
  min-height: 100vh;
  font-family: Roboto, sans-serif;
}
input {
  font-family: Roboto, sans-serif;
  &::placeholder {
    font-family: Roboto, sans-serif;
  }
}


</style>
