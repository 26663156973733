<template>
  <div class="signUp userVideo">
    <div class="content">
      <div class="left">
        <user-navbar-component/>
      </div>
      <div class="right">
        <div class="contentWrap">
          <div class="contentItem" v-for="video in videos" :key="video.id">
            <div class="imageWrap" v-html="video.videoUrl" :ref="`video${video.id}`">
            </div>
            <div class="name">
              {{video.title}}
            </div>
            <div class="desc">
              {{ video.description }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {mapActions, mapGetters, mapMutations} from "vuex";
import userNavbarComponent from "@/components/userNavbarComponent.vue";
export default {
  name: 'signup',
  components: {
    userNavbarComponent
  },
  data() {
    return {
      videos: []
    }
  },
  created() {
    this.getVideos()
  },
  mounted () {
    // this.register()
  },
  computed: {
    ...mapGetters(['StateToken'])
  },
  methods: {
    ...mapMutations(['logout']),
    logoutUser() {
      this.logout()
      this.$router.push('/sign-in')
    },
    getVideos() {
      axios.get(`https://portal.baltecousa.com/api/videos/visible`, {
        headers: { authorization: `Bearer ${this.StateToken.access}`}
      }).then(resp => {
        this.videos = resp.data.data


      })
    }
  }
}
</script>
<style scoped lang="scss">
@import "src/assets/styles";
.signUp {
  min-height: $pageHeight;
  display: flex;
  .left {
    height: $pageHeight;
    width: 50vw;
    background: $dark72;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 65px;
    background: url("@/assets/img/bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    .links {
      .link {
        margin-top: 32px;
        color: $light-silver;
        font-size: 16px;
        cursor: pointer;
      }
      .active {
        color: $gold-text;
      }
    }
    h1 {
      text-transform: uppercase;
      font-size: 40px;
      font-weight: 300;
      margin: 0;
      padding: 0;
      color: #fff;
    }
  }
  .right {
    height: calc($pageHeight - 130px) ;
    width: calc(50vw - 95px * 2);
    padding: 65px 95px;
    overflow: auto;

    .contentWrap {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      margin-bottom: 16px;
      .contentItem {
        text-align: left;
        .name {
          font-size: 20px;
          font-weight: 500;
        }
        .desc {
          font-size: 16px;
          font-weight: 300;
        }
        .imageWrap {
          //background: #B59F70;
          border-radius: 12px;
          margin-bottom: 12px;
          iframe {
            width: 200px !important;
          }
        }
      }
    }



    .form {
      width: 356px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .inputLabel {
      width: 365px;
      color: $black-text;
      font-size: 16px;
      font-weight: $font-light;
      letter-spacing: 0;
      margin-bottom: 12px;
    }
    input {
      width: calc(100% - 16px * 2);
      font-size: 16px;
      font-weight: $font-regular;
      margin-bottom: 12px;
      padding: 12px 16px;
      border-radius: 4px;
      outline: none;
      border: 1px solid $silver;
      color: $black-text;
      &::placeholder {
        color: $dark72;
      }
      &:hover {
        border: 1px solid $gold-text;
      }
      &:focus {
        border: 1px solid $gold-text;
        color: $black-text;
      }
    }
    .login {
      width: 100%;
      background: $black-text;
      color: #fff;
      border-radius: 139px;
      padding: 12px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      text-transform: uppercase;
    }
    .registration {
      width: 100%;
      background: #fff;
      color: $black-text;
      border: 1px solid $black-text;
      border-radius: 139px;
      padding: 12px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      text-transform: uppercase;
      margin-top: 16px;
    }
    a {
      color: $dark72;
      text-decoration: none;
      margin-top: 16px;
      font-size: 12px;
    }
  }
  .content {
    display: flex;
    position: fixed;
  }
}
</style>
