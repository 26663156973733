<template>
  <div class="headerComponent">
    <div class="logo">
      <img :src="require('/src/assets/svg/logo.svg')" alt="logo">
    </div>
    <div class="burger" @click="menuOpened = !menuOpened">
      <img :src="require('/src/assets/svg/burger.svg')" alt="" v-if="!menuOpened">
      <img :src="require('/src/assets/svg/burgerClose.svg')" alt="" v-else>
    </div>
    <div class="links">
      <a href="https://baltecousa.com/products/baths/stone-baths.html">
        products
      </a>
      <a href="https://baltecousa.com/about/balteco.html">
        about
      </a>
      <a href="https://baltecousa.com/contact/contact-us.html">
        contact
      </a>
      <a href="https://baltecousa.com/partners.html">
        Locate a showroom
      </a>
    </div>
    <div class="actions">
      {{ StateUserName ? StateUserName : 'LOGIN' }}
<!--      <img :src="require('/src/assets/svg/arrowDown.svg')" alt="arrow">-->
    </div>
    <div class="mobileMenu" v-if="menuOpened">
      <div class="mobileMenuLinks">
        <a href="https://baltecousa.com/products/baths/stone-baths.html">products</a>
        <a href="https://baltecousa.com/about/balteco.html">about</a>
        <a href="https://baltecousa.com/contact/contact-us.html">contact</a>
        <a href="https://baltecousa.com/partners.html">Locate a showroom</a>
      </div>
      <div class="mobileMenuName">
        <p>
          {{ StateUserName ? StateUserName : 'LOGIN' }}
        </p>
      </div>
      <div class="mobileMenuLogOut" v-if="StateUserName" @click="logOutUser">
        <p>
          SIGN OUT
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
export default {
  name: 'headerComponent',
  components: {
  },
  data() {
    return {
      menuOpened: false,
    }
  },
  mounted () {
  },
  computed: {
    ...mapGetters(['StateUserName'])
  },
  methods: {
    ...mapActions(["LogOut"]),
    logOutUser() {
      this.LogOut()
      this.$router.push('/admin-login')
    }
  }
}
</script>
<style scoped lang="scss">
@import "src/assets/styles";
.headerComponent {
  position: relative;
  z-index: 10;
  height: 60px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Roboto, sans-serif;
  padding: 0 14.89vw;
  .mobileMenu {
    display: none;
  }
  .logo {
    cursor: pointer;
  }
  .burger {
    display: none;
  }
  .links {
    height: 60px;
    position: absolute;
    display: flex;
    gap: 48px;
    left: 50%;
    transform: translateX(-50%);
    align-self: center;
    align-items: center;
    a {
      font-size: 12px;
      text-transform: uppercase;
      text-decoration: none;
      font-weight: 400;
      letter-spacing: 1.5px;
      color: $black-text;
      transition: $ease05s;
      &:hover {
        color: $gold-text;
      }
    }
  }
  .actions {
    cursor: pointer;
    background: $black-text;
    color: #fff;
    border-radius: 139px;
    padding: 12px 32px;
    font-size: 12px;
    transition: all .5s ease;
    &:hover {
      background: $gold-text;
      img {
        transform: rotate(-180deg);
      }
    }
    img {
      transition: $ease05s;
    }
  }
}
@media screen and (min-width: 769px) and (max-width: 1440px) {
  .headerComponent {
    padding: 0 40px;
    .links {
      gap: 25px;
    }
  }
}
@media screen and (max-width: 768px) {
  .headerComponent {
    padding: 0 16px;
    position: fixed;
    width: calc(100vw - 16px * 2);
    top: 0;
    left: 0;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 999;
    .mobileMenu {
      display: flex;
      position: fixed;
      left: 0;
      top: 60px;
      border-top: 1px solid #cfcfcf;
      //height: calc(100vh - 60px - 40px * 2);
      width: 100vw;
      background: #fff;
      flex-direction: column;
      align-items: center;
      text-align: left;
      box-shadow: 0 5px 10px -3px rgba(0, 0, 0, 0.1);
      .mobileMenuName, .mobileMenuLogOut {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 50px;
        color: $black-text;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.25em;
        text-transform: uppercase;
        text-decoration: none;
        width: 100%;
        border-bottom: 1px solid #f9f6f1;
        p {
          padding: 0 0 0 20px;
          margin: 0;
        }
      }
      .mobileMenuLinks {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        a {
          border-bottom: 1px solid #f9f6f1;
          color: $black-text;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.25em;
          text-transform: uppercase;
          text-decoration: none;
          width: 100%;
          padding: 17px 0 17px 20px;
        }
      }
    }
    .burger {
      display: block;
    }
    .links {
      display: none;
    }
    .actions {
      display: none;
    }
  }
}
</style>
